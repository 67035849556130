/* You can add global styles to this file, and also import other style files */

@import 'SCSS/_theme.scss';
@import 'SCSS/mixin.scss';
@import "~ng-pick-datetime/assets/style/picker.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: 'Montserrat-Regular';
    font-weight: 400;
    src: url(./assets/fonts/Montserrat-Regular.ttf) format("ttf");
  }
  @font-face {
    font-family: 'Montserrat-Medium';
    font-weight: 500;
    src: url(./assets/fonts/Montserrat-Medium.ttf) format("ttf");
  }
  @font-face {
    font-family: 'Montserrat-SemiBold';
    font-weight: 600;
    src: url(./assets/fonts/Montserrat-SemiBold.ttf) format("ttf");
  }
  @font-face {
    font-family: 'Montserrat-Bold';
    font-weight: 700;
    src: url(./assets/fonts/Montserrat-Bold.ttf) format("ttf");
  }
  body{
    color: #2C2C2C;
    font-size: 18px
  }
  .timeline li:first-child div:nth-child(2), .timeline li:last-child div:nth-child(2) {
    @apply before:content-[none] border-stroke;
  }
  .rating li input:checked ~ label svg path{
    fill: #F0C80B;
    stroke: #B69600;
  }
}
/* Reset CSS
   ========================================================================== */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

body {
  background: #f3f3f3;
  font-family: 'Poppins', sans-serif !important;
  color: #000000;
  font-size: 16px;
  line-height: 1;
  overflow-x: hidden;
}

.mat-tab-group {
  font-family: 'Poppins', sans-serif !important;
}
.material-symbols-outlined {
  font-variation-settings:
  'FILL' 0,
  'wght' 100,
  'GRAD' 0,
  'opsz' 24
}
/* Common CSS
   ========================================================================== */
ol,
ul {
  list-style: none;
}

h2,
h3,
h4,
h5 {
  color: #000000;
}

*:focus,
*:hover {
  outline: 0 !important;
}

a,
.btn {
  transition: all 0.5s ease;
  cursor: pointer;
}

button {
  cursor: pointer;
}

a:active,
a:hover,
a:focus,
.btn:focus,
.btn:active,
.btn:hover {
  outline: 0;
}

a {
  cursor: pointer;
  color: $default-color;
  text-decoration: none;
}

a:focus,
a:hover {
  color: $black-color;
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 72%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.4em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
  vertical-align: middle;
}

hr {
  box-sizing: content-box;
  height: 0;
}

svg:not(:root) {
  overflow: hidden;
}

video {
  width: 100%;
  height: auto;
}

.videosession {

  /* margin: -15px 0 0;*/
  video {
    max-height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.mt-6 {
  margin-top: 6rem !important;
}

.font-16 {
  font-size: 16px;
}

/*-------------------------------------------------------*/
/*  Button Css
/*-------------------------------------------------------*/
button {
  cursor: pointer;
}

.btn {
  border-radius: 3px;
  font-size: 15px;
  font-weight: 400;
  padding: 0.488rem 1rem;
}

.btn.focus,
.btn:focus {
  box-shadow: none !important;
}

.btn-sm {
  font-size: 13px;
  padding: .3rem;
}

.btn-lg {
  padding: 20px 45px;
  max-width: 100%;
  display: block;
}

.btn-primary {
  background: #0d4e94;
  border-color: #0d4e94;
  color: #ffffff !important;
}

.btn-primary:hover {
  background: #007BC2;
  border-color: #007BC2;
  color: #ffffff !important;
}
.btn-primary.disabled, .btn-primary:disabled {
  background-color: #dbdbdb;
  border-color: #dbdbdb;
  color: #7b7b7b!important;
  pointer-events: none;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle,
.btn-primary.focus,
.btn-primary:focus {
  background: #0d4e94;
  border-color: #0d4e94;
}

.btn-border {
  background: transparent;
  border: 2px solid #0d4e94;
  color: #0d4e94 !important;
  padding: 0.43rem 1rem;
}

.btn-border:hover,
.btn-border:focus {
  background: #0d4e94;
  border-color: #0d4e94;
  color: #ffffff !important;
}

.btn-red {
  background: #fc434e;
  border-color: #fc434e;
  color: #ffffff !important;
}

.btn-red:hover,
.btn-red:focus {
  background: #eb5261;
  border-color: #eb5261;
  color: #ffffff !important;
}

.btn-success {
  background: #1dc9b7;
  border-color: #1dc9b7;
  color: #ffffff !important;
}

.btn-success:hover,
.btn-success:focus {
  background: #17afa0;
  border-color: #17afa0;
  color: #ffffff !important;
}


.btn-purple {
  background: #883356;
  border-color: #883356;
  color: #ffffff !important;
}

.btn-purple:hover,
.btn-purple:focus {
  background: #ad416d;
  border-color: #ad416d;
  color: #ffffff !important;
}

.btn-plum {
  background: #563d6f;
  border-color: #563d6f;
  color: #ffffff !important;
}

.btn-plum:hover,
.btn-plum:focus {
  background: #704f90;
  border-color: #704f90;
  color: #ffffff !important;
}

.btn-secondary,
.btn-secondary:focus,
.btn-secondary.disabled,
.btn-secondary:disabled {
  background: #e6e6e6;
  border-color: #e6e6e6;
  color: #747373;
  opacity: 1;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  pointer-events: none;
}


.btn-grey,
.btn-grey:focus,
.btn-grey.disabled,
.btn-grey:disabled {
  background: #dbdbdb;
  border-color: #e6e6e6;
  color: #747373;
  opacity: 1;
}

.btn-grey:hover {
  background: #c7c7c7;
  border-color: #c7c7c7;
  color: #747373;
  opacity: 1;

}

.btn-mw {
  min-width: 125px;
}

.btn-info {
  color: #0d4e94;
  background-color: #b6d8fd;
  border-color: #b6d8fd;
}

.btn-info:hover {
  color: #fff;
  background-color: #0d4e94;
  border-color: #0d4e94;
}

.btn-text {
  color: #6e6e6e;
  font-weight: 300;
  padding: 0.5rem;
}

.btn-text:hover {
  background: #e9e9e9;
}

/*-------------------------------------------------------*/
/*  Badge and color Css
/*-------------------------------------------------------*/
.badge-primary,
.bg-primary {
  background-color: #0d4e94 !important;
}

.badge-blue,
.bg-blue {
  color: #fff !important;
  background-color: #6666FF !important;
}

.badge-success,
.bg-success {
  color: #fff !important;
  background-color: #1dc9b7 !important;
}

.badge-warning,
.bg-warning {
  color: #ffffff !important;
  background-color: #ffb822 !important;
}

.badge-danger,
.bg-danger {
  color: #fff !important;
  background-color: #eb5261 !important;
}

.badge-gray,
.bg-gray {
  color: #111 !important;
  background-color: #e9ecef !important;
}

.text-primary {
  color: #0d4e94 !important;
}

.text-success {
  color: #1dc9b7 !important;
}

.text-warning {
  color: #ffb822 !important;
}

.text-danger {
  color: #eb5261 !important;
}

.text-disable {
  color: #999999 !important;
}

.text-blue {
  color: #09305C !important;
}

/*-------------------------------------------------------*/
/*  Common Css
/*-------------------------------------------------------*/
.block {
  display: block;
}

.hide {
  display: none;
}

.relative {
  position: relative;
}

.pr-9 {
  padding-right: 9rem !important;
}

.pr-8 {
  padding-right: 8rem !important;
}

.font-300 {
  font-weight: 300 !important;
}

.font-400 {
  font-weight: 400 !important;
}

.font-500 {
  font-weight: 500 !important;
}

.font-600 {
  font-weight: 600 !important;
}

.pointer {
  cursor: pointer;
}

.textLink {
  cursor: pointer;
  color: #0d4e94 !important;
  font-weight: 300;
}

.boxshadow {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0 0px 6px rgba(0, 0, 0, .1);
  -webkit-box-shadow: 0 0px 6px rgba(0, 0, 0, .1);
  padding: 15px;
}

.boxshadow:hover {
  box-shadow: 0 0px 6px rgba(13, 78, 148, 0.2);
}

.word-break {
  word-break: break-all !important;
  white-space: normal !important;
}

.lh-base {
  line-height: 1.5;
}

/*-------------------------------------------------------*/
/*  Element Css
/*-------------------------------------------------------*/
.container {
  max-width: 1366px;
}

.wrapper {
  padding-top: 45px;
}

.wrapper .content {
  padding-top: 10px;
  min-height: calc(100vh - 56px);
}

.wrapper.nonlogin,
.wrapper.nonlogin .content {
  padding: 0;
}

.wrapper.nonlogin .content .technical-content,
.wrapper .content .technical-content {
  padding-top: 15px;
}

.section-box {
  padding: 20px;
  border: 1px solid #eee;
  background: #fff;
  position: relative;

  h3 {
    color: #494a6b;
    font-weight: 500;
    margin-bottom: 18px;
    font-size: 17px;
  }
}

.checkbox-focus-none {
  .mat-ripple-element {
    display: none !important;
  }
}


.rounded-circle {
  border-radius: 50% !important;
}

.margin-top40 {
  margin-top: 40px
}

.successClass {
  background: #018DB0;
  color: #fff;
}

.errorClass {
  background: red
}

.btn-design {
  background: linear-gradient(to left, #0d4e94 0%, #3b87d8 100%);
  color: #fff;
  min-width: 120px !important;
  margin-top: 15px !important;
  padding: 3px 0 !important;
}

.cancel-btn {
  color: #1f1f1f;
  padding: 0px 30px;
  border-radius: 4px;
  font-size: 15px;
  line-height: 43px;
  letter-spacing: 0.3px;
  margin-left: 15px;
  border: 1px solid #ccc;
  background: #fff;
}

.input-box {
  p {
    font-size: 13px;
    margin-bottom: 6px;
    color: #888;
  }

  .select-field {
    font-size: 14px;
    padding: 7px 18px;
    border: 1px solid #ccc;
    width: 100%;
    margin-bottom: 15px;
    background: #fff;
    border-radius: 5px;

    .mat-form-field-wrapper {
      padding-bottom: 0;
    }

    .mat-form-field-infix {
      border-top: 0;
    }

    .mat-select-value-text {
      color: #222
    }

    .mat-form-field-underline {
      display: none;
    }

    .mat-primary {
      .mat-option.mat-selected:not(.mat-option-disabled) {
        color: #111;
      }
    }
  }

  input {
    background: #fff;
    border: 1px solid #ccc;
    padding: .375rem .75rem;
    height: 40px;
    width: 100%;
    font-size: 14px;
    color: #495057;
    margin-bottom: 15px;
    line-height: 1.5;
    transition: all ease-in-out .2s;

    &:hover {
      border: 1px solid #f78441;
    }

    @include respond-to (media-xm) {
      font-size: 12px;
      height: 36px;
    }

  }

  .input-err {
    font-size: 12px;
    color: red;
    margin-bottom: 14px;
    margin-top: -14px;
    background: #fff4f4;
    padding: 4px 4px 6px 18px;
    border-radius: 4px;

    @include respond-to (media-sm) {
      font-size: 11px;
    }
  }

  .input-readonly {
    background: #f8f8f8;
  }
}



.loading-box {
  background: #fff;
  height: 240px;
  box-shadow: 0 0 3px 0px #eee;
  margin-bottom: 10px;
}

.mat-menu-panel {
  min-width: 170px !important;
}

.mat-menu-content {
  padding: 0 !important;
}

.mat-menu-item {
  height: 40px !important;
  line-height: 40px !important;
  border-bottom: 1px solid #eee !important;
  font-size: 13px !important;
}

.blue-btn-design {
  background: linear-gradient(to left, #0d4e94 0%, #3b87d8 100%);
  color: #fff;
  border: 0;
  padding: 0px 30px;
  border-radius: 4px;
  font-size: 15px;
  line-height: 44px;
  letter-spacing: 0.3px;
}

.successClass {
  background: #0D4E94;
}

.errorClass {
  background: red;
}

app-snack-bar {
  text-align: center;
  color: #fff;
}

.language-change-btn {
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-wrapper {
    padding-bottom: 0;
  }

  .mat-form-field-infix {
    width: 55px;
    border-top: 0;
  }
}

.mat-option {
  font-size: 14px !important;
  line-height: 2.6em !important;
  height: 2.6em !important;
}

.mat-expansion-panel {
  border-radius: 0 !important;
}

.mat-expansion-panel-header {
  height: auto !important
}

.isLoading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(250, 250, 250, 0.8);
  z-index: 9;

  mat-spinner {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
}

.no-data {
  text-align: center;
  padding: 100px 0;
  background: #f8f8f8;
  color: #7c7c7c;
  font-size: 14px;
}

.section-list {
  position: relative;

  ul {
    li {
      border-bottom: 1px solid #eee;
      margin-bottom: 10px;
      width: 100%;

      @include respond-to(media-sm) {
        margin-bottom: 8px;
      }

      h4 {
        color: #585880;
        font-weight: 500;
        font-size: 14px;

        @include respond-to(media-sm) {
          font-size: 13px;
        }
      }

      .form-label {
        color: #aaa;
      }

      p {
        color: #111;
        font-size: 13px;
        margin-top: 5px;
        margin-bottom: 8px;

        @include respond-to(media-sm) {
          font-size: 12px;
          margin-bottom: 6px;
        }
      }
    }
  }
}

button.add-btn-design {
  background: none;
  border: none;
  margin: 5px 0;
  padding: 0;
}

button.close-btn-design {
  padding: 0;
  background: none;
  border: none;
}

.toggle-btn-design {
  p {
    font-size: 13px;
    margin-bottom: 6px;
    color: #888;
  }

  .mat-button-toggle-label-content {
    line-height: 35px;
  }

  .mat-button-toggle-checked {
    background-color: #2169c7;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: #fff;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 32px;
    font-size: 13px;
  }
}

.question-difficult-level {
  margin-bottom: 12px;

  .mat-button-toggle-checked {
    background-color: #2169c7;
  }

  .mat-button-toggle-checked.mat-button-toggle-appearance-standard {
    color: #fff;
  }

  .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 37px;
    font-size: 13px;
  }
}

/*** New css ***/
.card {
  background: $white-color;
  border-radius: 0;
  margin-bottom: 30px;

  .card-body {
    padding: 1rem;

    .status-item {
      h4 {
        margin-bottom: 0.8rem;
        font-weight: 500;
        font-size: 16px;
      }

      h3 {
        font-size: 1.4rem;
        margin-bottom: 0.4rem;
      }

      p.text-muted {
        font-size: 11px;
        line-height: 1.2rem;

        span {
          i {
            font-size: 13px;
            vertical-align: middle;
          }
        }
      }

    }

    .progressItem {
      margin: 2rem 0;

      .value {
        font-size: 13px;
        margin-bottom: 8px;
        display: flex;
        display: -webkit-flex;

        .text {
          margin-left: auto;
        }
      }

      .progress {
        height: 6px;
      }
    }
  }

  .card-title {
    padding: 0.75rem 1rem;
    border-bottom: 1px solid $border-color;

    h3 {
      font-size: 1rem;
      color: $text-color;

      .icon {
        vertical-align: bottom;
        margin-right: 6px;
        width: 24px;
      }
    }
  }
}

.card-shadow {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border: 0px;
}

.checkicon .mat-checkbox .mat-checkbox-layout {
  background: #e6e6e6;
  width: 34px;
  height: 34px;
  display: block;
  border-radius: 0;
  line-height: 1;
}

.cardBox .topRightpos {
  position: absolute;
  right: 10px;
  top: 10px;
}

.cardBox .topRightpos.badge {
  top: 15px;
}

.checkicon .mat-checkbox-checked .mat-checkbox-layout {
  background: $default-color;
}

.checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background {
  background: transparent;
}

.checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-frame {
  opacity: 0;
}

.checkicon .mat-checkbox .mat-checkbox-layout,
.checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container,
.checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background-label {
  width: 18px;
  height: 18px;
}

/*.cardBox .checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background .mat-checkbox-checkmark { width: 27px; margin-top: 4px; margin-left: 3px; opacity: 1; }*/
.checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background .mat-checkbox-checkmark .mat-checkbox-checkmark-path {
  stroke-dashoffset: 0;
}

.checkicon .mat-checkbox .mat-checkbox-layout .mat-checkbox-background .mat-checkbox-checkmark {
  width: 15px;
  margin-top: -1px;
  margin-left: 1px;
  opacity: 1;
}

.checkicon .mat-checkbox-checked.mat-checkbox-disabled .mat-checkbox-layout {
  background: #bdbdbd;
}


/*-------------------------------------------------------*/
/* Table Css
/*-------------------------------------------------------*/
.table-default {
  min-width: 767px;
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-bottom: 0px;
}

.table-default th,
.table-default td {
  font-weight: 300;
  vertical-align: middle;
  white-space: nowrap;
}

.table-default thead th {
  vertical-align: middle;
  border: 0;
  background: #0d4e94;
  color: #fff;
  padding: 0.9rem 6px;
  border-bottom: 0px solid #f3f3f3;
  font-size: 14px;
  text-transform: capitalize;
}

.table-default tbody td {
  background: #fff;
  border: 0;
  padding: 5px 6px;
  min-height: 45px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
  /* color: #787777;*/
  font-size: 13px;
  line-height: 1.2rem;
}

.table-default tbody td .link {
  font-weight: 600
}

.table-default tbody td .btn-icon {
  padding: .063rem 0.2rem;
}

.table-center {
  text-align: center;
}

.table-default tbody td.textGray {
  color: #575656;
}

.table-hover tbody tr:hover td {
  color: #656565;
  background-color: #fafbfc;
}

.table-default .dividerLine {
  padding: 10px !important;
}

.table-default .dividerLine .divider {
  background: #00d6be;
  height: 39px;
  width: 4px;
  display: block;
}

.table-sticky {
  max-height: calc(100vh - 190px);
  overflow: auto;
}

.table-sticky table thead tr:nth-child(1) th {
  position: sticky;
  top: 0;
  z-index: 2;
}

.table-default tbody td.nofoundTxt,
.nofoundTxt {
  text-align: center;
  color: #9c9c9c;
  font-size: 1rem;
  padding: .9rem 10px;
  vertical-align: middle;
}


.matTabs .mat-tab-header {
  position: absolute !important;
  right: 0;
  top: 0;
}

.matTabs .mat-tab-header .mat-tab-label {
  background: #fff;
  border-radius: 3px;
  opacity: 1 !important;
  width: 30px;
  padding: 0 !important;
  height: 30px !important;
  min-width: 30px !important;
  margin-left: 13px;
}

.matTabs img.mattabIcon {
  width: 15px !important;
}

.matTabs .mat-tab-header {
  border: 0 !important;
}

.matTabs .mat-ink-bar {
  display: none;
}

.matTabs .tab-btn {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}


.matTabs .mat-tab-body-content {
  overflow: hidden;
  padding-bottom: 10px;
}

.matTabs .mat-tab-label-active {
  background: #cce5ff !important;
  color: #fff !important;
  border: 1px solid #015c67 !important;
  color: #0d4e94 !important;
}



.matBoxTab .mat-tab-header {
  position: relative !important;
  border-bottom: 1px solid #CCCCFF !important;
  margin-bottom: 13px;
}

.matBoxTab .mat-tab-header .mat-tab-label {
  width: auto !important;
  min-width: auto !important;
  height: auto !important;
  border: 0;
  background: transparent !important;
  margin: 0;
  color: #6e8d9f;
  /*
border-bottom: 2px solid transparent!important;*/
  font-weight: 400;
  padding: 10px 5px !important;
  opacity: 1;
}

.matBoxTab .mat-tab-header .mat-tab-labels {
  justify-content: space-between;
}

.matBoxTab .mat-tab-header .mat-tab-label-active {
  /* border: 0!important;
    background: transparent!important;
    border-bottom: 2px solid #0d4e94 !important;
border-radius: 0px;*/
  color: #0d4e94;
}

.mat-tab-group .mat-ink-bar,
.matBoxTab .mat-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: #0d4e94 !important;
  box-shadow: 0px 0px 2px #0d4e94;
  height: 3px;
}

.mat-modal-tab .mat-tab-header {
  margin: -15px -15px 0;
}

.mat-modal-close {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2;
}

.edit-job-modal .table-responsive {
  max-height: calc(100vh - 190px);
}

.matBoxTab .mat-tab-body-content {
  overflow: hidden;
}

.matBoxTab .mat-ripple-element {
  display: none;
}

.rightTab .matBoxTab .mat-tab-body-content {
  padding: 0 2px;
}

.mat-paginator .mat-paginator-page-size-select {
  margin-top: 0px !important;
}

.mat-paginator .mat-paginator-page-size-select .mat-form-field-wrapper {
  padding-bottom: 0.6rem !important;
}

.mat-paginator-container {
  min-height: 30px !important;
}

/*
.mat-paginator-container .mat-icon-button {
  height: 24px;
  line-height: 24px;
}

.mat-paginator-container .mat-icon-button svg.mat-paginator-icon {
  height: 24px;
  width: 24px;
}

.mat-paginator-container .mat-paginator-page-size-select {
  line-height: 1;
}



.mat-paginator-container .mat-form-field-appearance-legacy .mat-form-field-infix {
  border: 0;
}

.mat-paginator .mat-paginator-page-size-select .mat-form-field-wrapper {
  padding: 0px!important;
}

.mat-paginator-container .mat-form-field-appearance-legacy .mat-form-field-underline {
  bottom: 0px;
}*/

/**** Candidates Search Filter Css ***/
.searchFilter .search {
  position: relative;
}

.searchFilter .search input[type="search"],
.searchFilter .search input[type="text"] {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  display: block;
  width: 100%;
  height: 52px;
  padding: 15px;
  font-size: 15px;
  padding-right: 34px;
}

.searchFilter .search .btn-search {
  background: transparent;
  border: 0;
  position: absolute;
  right: 0;
  top: 0;
  color: #0d4e94;
  width: 40px;
  height: 52px;
}

.searchFilter .search .btn-search i {
  vertical-align: middle;
}

.searchFilter .selectgroup {
  background: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  height: 52px;
  padding: 15px 0px 15px 15px;
  font-size: 15px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.searchFilter .selectgroup>label {
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  margin-right: 10px;
}

.searchFilter .selectgroup .mat-form-field-underline {
  display: none;
}

.searchFilter .selectgroup .mat-form-field-wrapper {
  padding: 0;
}

.searchFilter .selectgroup .mat-form-field {
  display: inline-table;
  line-height: 1;
  position: relative;
  height: 52px;
  margin-top: 0;
}

.searchFilter .selectgroup .mat-form-field-wrapper .mat-form-field-infix {
  margin: 0;
  align-items: flex-start;
  width: 146px;
}

.searchFilter .selectgroup .mat-form-field-flex {
  align-items: baseline;
}

.searchFilter .selectgroup .mat-select-arrow-wrapper {
  background: #e8e8e8;
  top: -18px;
  width: 36px;
  position: absolute;
  right: 0px;
  height: 50px;
  border-radius: 0px 5px 5px 0px;
}

.searchFilter .selectgroup .mat-select-trigger {
  width: 100%;
}

.searchFilter .selectgroup .mat-select-arrow-wrapper .mat-select-arrow {
  margin: 23px auto 0;
}

.searchFilter .selectgroup .mat-form-field-label-wrapper {
  display: none;
}

.searchFilter .selectgroup .mat-select-value-text {
  color: #0d4e94;
  font-weight: 400;
  font-size: 14px;
}

.searchFilter .selectgroup .form-control {
  border: 0px;
}

.searchFilter .selectgroup .selectfield {
  background: url('/assets/images/selectbg.png');
  background-repeat: no-repeat;
  background-position: center right;
  border: 0px;
  height: 53px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  margin-left: auto;
  padding-right: 52px;
}

.searchFilter .button .btn {
  height: 52px;
}

.form-radio-group {
  grid-gap: 15px;
}

.form-radio-group .mat-radio-button {
  font-family: 'Poppins', sans-serif;
  font-size: 13.5px;
  font-weight: 300;
  color: #565656;
  line-height: 1.2;
}

.control-label-sm {
  color: #0d4e94;
  font-size: 12px;
  margin-bottom: 14px;
}

.form-radio-group .mat-radio-outer-circle {
  width: 16px;
  height: 16px;
}

.form-radio-group .mat-radio-container {
  width: 16px;
  height: 16px;
}

.form-radio-group .mat-radio-inner-circle {
  width: 16px;
  height: 16px;
}

.form-radio-group .mat-radio-button .mat-radio-ripple {
  display: none;
}

.form-radio-group .mat-radio-button .mat-radio-inner-circle {
  background-color: #0d4e94 !important;
}

.form-radio-group .mat-radio-button.mat-radio-checked .mat-radio-outer-circle {
  border-color: #0d4e94 !important;
}

/*-------------------------------------------------------*/
/*  Gradient Color  Css
/*-------------------------------------------------------*/
.purpleColorBg {
  background: rgb(54, 38, 173);
  background: -moz-linear-gradient(left, rgba(54, 38, 173, 1) 0%, rgba(174, 96, 208, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(54, 38, 173, 1) 0%, rgba(174, 96, 208, 1) 100%);
  background: linear-gradient(to right, rgba(54, 38, 173, 1) 0%, rgba(174, 96, 208, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3626ad', endColorstr='#ae60d0', GradientType=1);
}

.blueshadev2ColorBg {
  background: #36d1dc;
  background: -moz-linear-gradient(left, #36d1dc 0%, #5b86e5 100%);
  background: -webkit-linear-gradient(left, #36d1dc 0%, #5b86e5 100%);
  background: linear-gradient(to right, #36d1dc 0%, #5b86e5 100%);
}

.redshadeColorBg {
  background: rgb(255, 126, 154);
  background: -moz-linear-gradient(left, rgba(255, 126, 154, 1) 0%, rgba(254, 184, 122, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 126, 154, 1) 0%, rgba(254, 184, 122, 1) 100%);
  background: linear-gradient(to right, rgba(255, 126, 154, 1) 0%, rgba(254, 184, 122, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7e9a', endColorstr='#feb87a', GradientType=1);
}

.blueshadeColorBg {
  background: rgb(106, 122, 244);
  background: -moz-linear-gradient(left, rgba(106, 122, 244, 1) 0%, rgba(90, 214, 250, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(106, 122, 244, 1) 0%, rgba(90, 214, 250, 1) 100%);
  background: linear-gradient(to right, rgba(106, 122, 244, 1) 0%, rgba(90, 214, 250, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6a7af4', endColorstr='#5ad6fa', GradientType=1);
}

.greenColorBg {
  background: rgb(2, 186, 132);
  background: -moz-linear-gradient(left, rgba(2, 186, 132, 1) 0%, rgba(4, 181, 167, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(2, 186, 132, 1) 0%, rgba(4, 181, 167, 1) 100%);
  background: linear-gradient(to right, rgba(2, 186, 132, 1) 0%, rgba(4, 181, 167, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#02ba84', endColorstr='#04b5a7', GradientType=1);
}

.yellowColorBg {
  background: rgb(255, 188, 48);
  background: -moz-linear-gradient(left, rgba(255, 188, 48, 1) 0%, rgba(255, 221, 38, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 188, 48, 1) 0%, rgba(255, 221, 38, 1) 100%);
  background: linear-gradient(to right, rgba(255, 188, 48, 1) 0%, rgba(255, 221, 38, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbc30', endColorstr='#ffdd26', GradientType=1);
}

.skygradient {
  background: rgb(54, 38, 173);
  background: -moz-linear-gradient(left, rgba(54, 38, 173, 1) 0%, rgba(174, 96, 208, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(54, 38, 173, 1) 0%, rgba(174, 96, 208, 1) 100%);
  background: linear-gradient(to right, rgba(54, 38, 173, 1) 0%, rgba(174, 96, 208, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#3626ad', endColorstr='#ae60d0', GradientType=1);
}

.lightredgradient {
  background: rgb(255, 126, 154);
  background: -moz-linear-gradient(left, rgba(255, 126, 154, 1) 0%, rgba(254, 184, 122, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 126, 154, 1) 0%, rgba(254, 184, 122, 1) 100%);
  background: linear-gradient(to right, rgba(255, 126, 154, 1) 0%, rgba(254, 184, 122, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7e9a', endColorstr='#feb87a', GradientType=1);
}

.lightBluegradient {
  background: rgb(106, 122, 244);
  background: -moz-linear-gradient(left, rgba(106, 122, 244, 1) 0%, rgba(90, 214, 250, 1) 100%);
  background: -webkit-linear-gradient(left, rgba(106, 122, 244, 1) 0%, rgba(90, 214, 250, 1) 100%);
  background: linear-gradient(to right, rgba(106, 122, 244, 1) 0%, rgba(90, 214, 250, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#6a7af4', endColorstr='#5ad6fa', GradientType=1);
}


/*-------------------------------------------------------*/
/* customForm Css
/*-------------------------------------------------------*/
.form-label {
  display: block;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  text-transform: capitalize;
}

.form-control {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  color: #111111;
  box-shadow: none;
  padding: .375rem;
  font-size: 14px;
  outline: none;
  height: calc(2.25rem + 4px);
}

.form-control:focus,
.form-control:hover {
  box-shadow: none;
  border-color: #ced4da;
}

.errorMsg {
  color: red;
  font-size: 12px;
  font-weight: 500;
}

.req {
  color: red;
  font-size: 12px;
}

.form-control .mat-input-element {
  color: #111111;
}



.field-date .mat-form-field-wrapper {
  padding: 0;
}

.field-date .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}

.field-date .mat-form-field-wrapper .mat-form-field-flex {
  background: #fff;
  border: 1px solid #ced4da;
  width: 100%;
  padding: .475rem .75rem;
  font-size: 14px;
  height: calc(2.25rem + 2px);
}

.field-date .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  width: auto;
  margin: 0;
  border: 0;
  padding: 0;
}

.field-date .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-label-wrapper {
  min-height: 16px;
  top: 0;
  bottom: 0;
  padding: 0;
  margin: auto;
}

.field-date .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-label-wrapper .mat-form-field-label {
  transform: none;
  -webkit-transform: none;
  top: 0;
}

.field-date .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  position: relative;
  width: 100%;
  margin-top: 0;
}

.field-date .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper .mat-error {
  width: auto;
  margin-top: 0.25rem;
  font-size: 13px;
  margin-right: 10px;
  color: #dc3545;
}

.field-date .mat-form-field-invalid .mat-input-element {
  caret-color: #dc3545;
}

.field-date .mat-form-field.mat-form-field-invalid .mat-form-field-label {
  color: #dc3545;
}

.field-date .mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex {
  border-color: red;
}



.field-date .mat-form-field {
  width: 100%;
}

.form-control .mat-select-value {
  padding: 3px 0;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element {
  background: #0d4e94;
}

.mat-checkbox-indeterminate.mat-warn .mat-checkbox-background,
.mat-checkbox-checked.mat-warn .mat-checkbox-background {
  background-color: #0d4e94;
}

.resizeno {
  resize: none;
}

.slide-toggle {
  display: inline-block;
  height: 28px;
  position: relative;
  width: 56px;
}

.slide-toggle input {
  display: none;
}

.slide-toggle .slider {
  border: 1px solid #bdc7d1;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: .4s;
}

.slide-toggle .slider:before {
  background-color: #bdc7d1;
  bottom: 4px;
  content: "";
  height: 18px;
  left: 4px;
  position: absolute;
  transition: .4s;
  width: 18px;
}

.slide-toggle input:checked+.slider {
  background-color: #0d4e94;
  border-color: #0d4e94;
}

.slide-toggle input:checked+.slider:before {
  background-color: #fff;
  transform: translateX(26px);
}

.slide-toggle .slider.round {
  border-radius: 34px;
}

.slide-toggle .slider.round:before {
  border-radius: 50%;
}

.matinput-date {
  position: relative;
}

.matinput-date .mat-datepicker-toggle {
  position: absolute;
  top: 0px;
  right: 0;
}

.matinput-date .mat-datepicker-toggle .mat-ripple,
.matinput-date .mat-datepicker-toggle .mat-button-focus-overlay {
  opacity: 0 !important;
}

.matinput-date .form-control[readonly] {
  background-color: #fff;
}




.box-shadow {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
  padding: 15px 24px;
  margin-bottom: 15px;
  height: calc(100% - 15px);

  h6 {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #697fcd;

    .info-icon {
      top: -1px;
      position: relative;
    }
  }

  .errorMsg {
    font-weight: 300;
    margin-top: 0px;
  }
}

.field-group {
  margin-bottom: 20px;
}

.field-group .control-label {
  display: block;
  width: 100%;
  color: #0d4e94;
  font-size: 11px;
  font-weight: 300;
  margin-bottom: 3px;
  letter-spacing: 0;
}

.field-group .field-control {
  width: 100%;
  border: 0px;
  border-bottom: 1px solid #d8d7d7;
  appearance: none;
  font-size: 14px;
  font-weight: 300;
  color: #1a1a1a;
  padding: 0px 0px 3px;
  font-family: "Poppins", sans-serif !important;
  height: 24px;
}

.field-group .field-control::-webkit-input-placeholder {
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 300;
  opacity: 1;
}

.field-group .field-control::-moz-placeholder {
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 300;
  opacity: 1;
}

.field-group .field-control:-ms-input-placeholder {
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 300;
  opacity: 1;
}

.field-group .field-control:-moz-placeholder {
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 300;
  opacity: 1;
}

.field-group select.field-control {
  background-image: url(/assets/images/select-arrow.svg);
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 11px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 15px;
  line-height: 17px;
}

.form-select {
  background-image: url(/assets/images/select-arrow2.svg);
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 18px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 27px;
}



/*-------------------------------------------------------*/
/*  Card Box List Css
/*-------------------------------------------------------*/
.cardBox {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
}

.cardBoxList {
  display: flex;
  display: -webkit-flex;
  padding: 7.5px;
  width: 10%;
}

@media (max-width: 5400px) {
  .cardBoxList {
    width: 14.28%;
  }

  .candidateList.open_mob_tab .cardBoxList {
    width: 12.5%;
  }
}

@media (max-width: 3400px) {
  .cardBoxList {
    width: 16.66%;
  }

  .candidateList.open_mob_tab .cardBoxList {
    width: 14.28%;
  }
}

@media (max-width: 2800px) {
  .cardBoxList {
    width: 20%;
  }

  .candidateList.open_mob_tab .cardBoxList {
    width: 16.66%;
  }
}

@media (max-width: 1800px) {
  .cardBoxList {
    width: 25%;
  }

  .candidateList.open_mob_tab .cardBoxList {
    width: 20%;
  }
}

@media (max-width: 1200px) {
  .cardBoxList {
    width: 33.33%;
  }

  .candidateList.open_mob_tab .cardBoxList {
    width: 25%;
  }
}

@media (max-width: 990px) {
  .cardBoxList {
    width: 50%;
  }

  .candidateList.open_mob_tab .cardBoxList {
    width: 33.33%;
  }
}

@media (max-width: 640px) {
  .cardBoxList {
    width: 100%;
  }
}



.cardContent {
  background: #ffffff;
  border: 1px solid #ebebeb;
  box-shadow: 0px 5px 7px 0px rgb(0 0 0 / 1%);
  -webkit-box-shadow: 0px 5px 7px 0px rgb(0 0 0 / 1%);
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px 5px 0;
  margin-bottom: 0px;
  font-size: 13px;
  color: #000;
  text-align: center;
  position: relative;
}

.cardBox .txtDesc {
  line-height: 1.5rem;
}

.cardBox .cardContent .linkTxt {
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
}

.cardBox .cardContent .link {
  cursor: pointer;
  color: #0d4e94;
  font-weight: 500;
}

.cardBox .cardContent .date {
  color: #575656;
}

.cardBox .cardContent .name,
.cardBox .cardContent .name-p2 {
  line-height: 1rem;
}

.star {
  font-size: 10px;
  vertical-align: baseline;
  color: #FFC107;
}

.cardBox .cardContent .name .star {
  margin-right: 3px;
  font-size: 10px;
  vertical-align: baseline;
  color: #FFC107;
}

.cardBox .cardContent .name .star i.disable {
  color: #e6e6e6;
}

.cardContent .userImage {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 3px 0px #ccc;
  margin: auto;
  border: 2px solid #fff;
  margin-top: 6px;
  margin-bottom: 5px;
}

.cardContent .userImage img {
  width: 100%;
  object-fit: cover;
  height: 62px;
  border-radius: 50%;
}

.cardBox .cardContent .name-p2 {
  padding: 0 2.2rem;
}

.cardBox .textRow {
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  margin: 5px 0;
}

.cardBox .textRow .txtcol {
  flex-basis: 50%;
  min-width: 50%;
  position: relative;
  text-align: center;
  padding: 8px 0;
}

.cardBox .textRow .txtcol:after {
  content: "";
  background: #e1e1e1;
  height: calc(100% - 15px);
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1px;
  right: 0;
  position: absolute;
}

.cardBox .textRow .txtcol:last-child:after {
  display: none;
}

.cardBox .textRow .txtcol label {
  font-weight: 400;
  color: #909090;
  font-size: 13px;
  display: block;
  text-align: center;
}

.cardBox .textRow .txtcol span {
  color: #0d4e94;
  font-size: 13px;
  line-height: 1rem;
  font-weight: 300;
}

.cardBox .cardContent .companylogo {
  font-weight: 400;
  font-size: 13px;
}

.cardBox .cardContent .companylogo img {
  height: 18px;
  width: auto;
  margin-right: 4px;
  max-width: 100%;
}

.cardBox .cardContent .text {
  line-height: 1.1rem;
}

.cardBoxList.nofoundTxt {
  width: 100% !important;
}

.cardBoxList.nofoundTxt .cardContent {
  display: block;
  color: #8c8c8c;
}

.cardBoxList.nofoundTxt i {
  font-size: 24px;
  color: #bdbdbd;
  vertical-align: middle;
}

.detailList {
  padding: 30px 0;
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
}

.detailList .detailCol {
  padding-right: 5px;
}

.detailList .detailCol ul li {
  text-align: left;
  list-style: none;
  margin-bottom: 10px;
  font-size: 12px;
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.detailList .detailCol ul li label {
  width: 68px;
  min-width: 68px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-right: 5px;
}

.detailList .detailCol ul li .wordBreak {
  word-break: break-all;
}

.detailList .applicants {
  font-size: 12px;
  border-left: 1px solid #e1e1e1;
  padding: 5px 0 5px 5px;
  margin-left: auto;
}

.detailList .applicants .number {
  font-weight: 400;
  font-size: 2rem;
  margin-bottom: 5px;
}

.detailList .detailCol ul li:last-child {
  margin-bottom: 0;
}

.cardBox .cardContent .bottom {
  margin-top: auto;
}

.cardBox .blue-divider {
  background: #0d4e94;
  height: 1px;
  margin: 8px 0;
}

.cardBox .dividerLine {
  background: #e1e1e1;
  height: 1px;
  margin: 10px 0;
}

.cardBox .cardContent .bottom .btn-sm {
  font-size: 14px;
  padding: 6px 14px;
}

.cardBox .positionRight {
  position: absolute;
  right: 3px;
  top: 3px;
  display: flex;
}

.cardBox .positionLeft {
  position: absolute;
  left: 3px;
  top: 3px;
  display: flex;
}

.cardBox .positionLeft .mat-icon,
.cardBox .positionRight .mat-icon {
  font-size: 20px;
}

.cardBox .cardContent .user-icons {
  padding: 0 15px;
}

.relative {
  position: relative;
}

.checkicon {
  position: relative;
  display: inline-block;
  right: 0;
  top: 0;
  width: auto;
  height: auto;
  margin-right: 8px;
  vertical-align: middle;
}

.video-date .mat-icon,
.tech-date .mat-icon {
  font-size: 15px !important;
  width: auto;
  height: auto;
  margin-right: 2px;
}

.mat-tooltip {
  background: rgba(0, 0, 0, 0.8);
  font-size: 12px;
  line-height: 1rem;
  position: relative;
  margin: 0px !important;
  max-width: unset !important;
  white-space: pre-line !important;
  max-height: 400px !important;
  overflow-y: auto !important;
}

/*mat-tooltip-component{margin: 20px 15px 10px; position: relative;}
mat-tooltip-component:after{ content: "";
position: absolute;
top: 100%;
left: 49.5%;
border-width: 5px;
border-style: solid;
border-color: rgba(0,0,0,0.8) transparent transparent;
}*/

.breadcrumbLink .breadcrumb {
  background: transparent;
  width: auto;
  margin: 0;
  padding: 0;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
}

.breadcrumbLink .breadcrumb li.breadcrumb-item {
  font-weight: 400;
  font-size: 13px;
  color: #111;
}

.dividerLine:after {
  content: "";
  background: #ececec;
  width: 1px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
}


h3.verifyTitle {
  font-size: 1rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
}

/*** Modal Css **/
.mat-dialog-container {
  padding: 1rem !important;
}

.mat-dialog-content {
  margin: 0 -1rem !important;
  padding: 1rem !important;
}

.mat-dialog-container .matModal {
  padding: 0;
}

.matModal button.mat-button {
  margin-left: auto;
  padding: 0;
  min-width: 32px;
  height: 22px;
  background: transparent;
}

.matModal .mat-button .mat-button-focus-overlay,
.matModal .mat-button:hover .mat-button-focus-overlay,
.matModal .mat-button-ripple.mat-ripple {
  opacity: 0 !important;
}

.matModal .modal-header {
  background: #0d4e94;
  border-radius: 0px;
  color: #fff;
  margin: -1rem;
  margin-bottom: 1rem;
}

.matModal .modal-header .modal-title {
  font-size: 1.2rem;
  color: #fff;
  font-weight: 400;
}

.matModal .modal-header .close {
  height: auto;
  width: 50px;
  min-width: auto;
  line-height: 1;
  padding: 16px 0px;
  text-align: center;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.card-verify {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, .125);
  padding: 15px;
  margin: 15px 0;
}

.card-verify h2.title {
  padding: 0;
  font-size: 1.3rem;
  font-weight: 400;
  margin-bottom: 15px;
}

.card-verify h2.title:after {
  content: "";
  background: #0d4e94;
  width: 30px;
  height: 2px;
  display: block;
  margin-top: 6px;
  margin-bottom: 20px;
}

.mat-form-field-appearance-standard:not(.mat-form-field-disabled) .mat-form-field-flex:hover~.mat-form-field-underline .mat-form-field-ripple {
  display: none;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: inherit;
}

/* Video Session **/

::-webkit-scrollbar {
  width: 1vw;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background-color: #999999;
}

::-webkit-scrollbar {
  height: 10px;
  /* height of horizontal scrollbar ← You're missing this */
  width: 10px;
  /* width of vertical scrollbar */
  background-color: #F5F5F5;
}

.notfoundtext {
  text-align: center;
  font-weight: 600;
  color: #676565;
}

/** Responsive css **/
@media (max-width: 767px) {
  .card-verify .dividerLine {
    margin-bottom: 16px;
    border-bottom: 1px solid #ececec;
    padding-bottom: 15px;
  }

  .card-verify .dividerLine:after {
    display: none;
  }
}

.map-search .mat-form-field .mat-form-field-underline {
  display: none;
}

.map-search .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: #0d4e94;
}

.mat-form-field textarea {
  line-height: 1.2rem;
  white-space: pre-line;
}

.mat-form-field textarea.mat-input-element {
  padding: 0;
  margin: 0;
}

.gm-style .gm-style-iw-c {
  box-shadow: 0px 3px 16px 0px rgba(0, 0, 0, 0.32);
  padding: 0;
  overflow: visible !important;
}

.gm-style .gm-style-iw-d {
  overflow: visible !important;
}

.gm-style .gm-style-iw-c button.gm-ui-hover-effect {
  top: -14px !important;
  right: 6px !important;
  z-index: 9;
  background: #fff !important;
  opacity: 1;
  width: 26px !Important;
  height: 16px !important;
  border-radius: 3px;
}

.gm-style .gm-style-iw-c button.gm-ui-hover-effect img {
  margin: 2px 7px !important;
}

.agm-info-window-content .mapDialoguewrap {
  padding-top: 0px;
}

.gm-style div[style*="color: rgb(36, 18, 145); font-size: 10px; font-family: Roboto, Arial, sans-serif;"] {
  width: 20px;
  height: 20px;
  right: -25px;
  top: -35px;
  border-radius: 100%;
  background: #0d4e94;
  text-align: center;
  line-height: 20px;
  font-weight: 400;
  color: #fff !important;
  position: absolute;
  z-index: 100;
}

.skill-section .rating i {
  cursor: pointer;
  font-size: 21px;
}

/** Assesment FOrm **/
.videosession .matBoxTab .mat-tab-header .mat-tab-label {
  flex-grow: 1;
  padding: 13px 5px !important
}

.videosession .matBoxTab .mat-tab-header .mat-tab-label:first-child {
  padding-left: 10px !important;
}

.assesmentForm .mat-expansion-panel {
  background: transparent;
  box-shadow: none !important;
}

.assesmentForm .mat-expansion-panel .mat-expansion-panel-header {
  padding: 0px 4px 0 0;
  background: transparent !important;
  align-items: flex-start;
}

.assesmentForm .mat-expansion-panel .mat-expansion-panel-body {
  padding: 0 0 16px;
}

.assesmentForm .mat-expansion-panel .mat-expansion-panel-header span.mat-expansion-indicator {
  top: 1px;
  position: relative;
}

.assesmentForm .mat-expansion-panel.mat-expanded .mat-expansion-panel-header span.mat-expansion-indicator {
  top: 9px;
}

.assesmentForm .mat-expansion-panel .mat-expansion-panel-header h3.title {
  margin-bottom: 0px;
}

.assesmentForm .mat-expansion-panel.mat-expanded .mat-expansion-panel-header h3.title {
  margin-bottom: 20px;
}

.fullscreen .assesmentForm .assesment-form {
  max-height: 626px !important;
}

.fullscreen .chatSection {
  max-height: 569px !important;
}

.textFormat {
  max-width: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.videoFile #localmedia video,
.videoFile #localmedia iframe {
  height: calc(100vh - 40px);
  vertical-align: middle;
  object-fit: contain;
}

.videoFile.interviewerdisplay1 #localmedia video {
  object-fit: cover;
}


.information-message {
  background: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.1);
  max-width: 768px;
  margin: 5rem auto;
  padding: 1.5rem;
  text-align: center;

  h5 {
    font-size: 1.5rem;
  }
}

.row-sm {
  margin: 0 -7.5px;

  [class*="col-"] {
    padding: 0 7.5px;
  }
}

//20200919 Akhilesh, jira no 960
.chart-size {
  width: 100px !important;
}



/** Assesment FOrm Css ***/
.score-form {
  position: relative;

  .w-100 {
    width: 100%;

    .mat-form-field-infix {
      width: 100%;
    }
  }

}

.score-form .btn-close {
  position: absolute;
  right: 0px;
  background: transparent;
  border: 0px;
  color: #515151 !important;
}

.score-form .mat-tab-header {
  margin: -15px -15px 20px;
}

.score-form .mat-tab-header .mat-tab-label {
  font-size: 17px;
}

.score-form .mat-tab-header .mat-tab-label-active {
  color: #0D4E94;
  opacity: 1;
}

.score-form .mat-tab-body-content {
  padding: 0 15px;
}

.score-form .input-phone {
  width: 130px;
}

.score-form .input-experience {
  width: 90px;
}

.input-auto .mat-form-field-infix {
  width: auto;
}

.score-form .section {
  border-top: 1px solid rgb(112 112 112 / 20%);
  padding: 15px;
}

.score-form .section h2 {
  color: #0D4E94;
  font-weight: 400;
  margin-bottom: 14px;
}

.score-form .mat-form-field {
  font-size: 15px;
}

/** Video Session Page css **/
.videoBox .videoFile .sharescreenoverlay video {
  height: calc(100vh - 91.23px);
  vertical-align: middle;
  object-fit: contain;
}

.videoBox .videoFile .pinscreenvideo video {
  height: calc(100vh - 40px);
  vertical-align: middle;
}

.videoFile div#remotemedia video {
  height: 100%;
}

.video-section .videoBox div#remotemedia .icon i.pin {
  background: url('/assets/images/push_pin-outline.svg');
  background-repeat: no-repeat;
  background-repeat: no-repeat;
  background-size: 18px;
  overflow: hidden;
  text-indent: -1000px;
  width: 18px;
  height: 18px;
}

.score-form .skill-section .skill-items .rating .mat-checkbox {
  font-size: 15px;
  color: #111;
  vertical-align: 6px;
}

.readonly .mat-form-field-ripple {
  opacity: 0 !Important;
}

.my-custom-tooltip {
  max-width: unset !important;
  white-space: pre-line !important;
}

/** Mat CHeckbox Color **/
mat-checkbox[color="white"] .mat-checkbox-frame {
  border-color: rgba(255, 255, 255, 0.7);
}

.mat-slide-primary.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0d4e94;
}

.mat-slide-primary.mat-slide-toggle .mat-slide-toggle-ripple {
  display: none;
}

.mat-slide-primary.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background: rgb(13 78 148 / 54%);
}

.mat-slide-primary .mat-slide-toggle-thumb {
  background: #e6e6e6;
}

.material-select {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  font-size: 14px;
  overflow: hidden;
}

.material-select .mat-form-field-wrapper {
  padding-bottom: 0;
}

.material-select .mat-form-field-flex {
  background: #fff;
  padding-bottom: 0.5rem;
}

.material-select .mat-form-field-underline {
  display: none;
}

.material-select .mat-form-field-infix {
  border: 0;
  padding: 0.4rem 0 0rem;
}

.material-select .mat-form-field-flex .mat-form-field-label {
  padding-top: 6px;
}

.material-select.mat-focused.mat-primary .mat-select-arrow {
  color: #0d4e94;
}

.material-select .mat-select-placeholder {
  color: rgba(0, 0, 0, 0.42);
}

.material-select.mat-focused .mat-form-field-label,
.material-select .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #0d4e94;
}

.material-select .mat-option.mat-selected:not(.mat-option-multiple) {
  background: rgba(0, 0, 0, 0.03);
}

.material-select .mat-select-arrow-wrapper {
  transform: translateY(-20%) !important;
}

.videosession .rightTab ngx-doc-viewer {
  height: calc(100vh - 96px) !important;
}

@media (max-width: 990px) {

  .videoFile #localmedia video,
  .videoFile #localmedia iframe {
    height: calc(100vh - 40px - 61px);
  }

  .videoBox .videoFile .sharescreenoverlay video {
    height: calc(100vh - 40px - 80px);
  }
}

@media (max-width: 767px) and (orientation: portrait) {

  .videoFile #localmedia video,
  .videoFile #localmedia iframe {
    height: calc(100vh - 40px - 61px - 100px);
  }

  .videoBox .videoFile .sharescreenoverlay video {
    height: calc(100vh - 40px - 193px);
  }
}


.mat-snack-bar-container button.close {
  background: transparent !important;
  color: #fff;
  opacity: 1;
  overflow: hidden;
  text-indent: -1000px;
  width: 18px;
  height: 18px;
  position: relative;
}

.mat-snack-bar-container button.close:before,
.mat-snack-bar-container button.close:after {
  position: absolute;
  left: 8px;
  content: ' ';
  height: 18px;
  width: 2px;
  background-color: #fff;
}

.mat-snack-bar-container button.close:before {
  transform: rotate(45deg);
}

.mat-snack-bar-container button.close:after {
  transform: rotate(-45deg);
}



.searchFilter-v2 {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  height: 46px;
  margin-bottom: 1rem;
}

.searchFilter-v2 .material-input .mat-form-field-wrapper {
  padding-bottom: 0;
}

.searchFilter-v2 .material-input .mat-form-field-flex {
  background: #fff;
  padding-bottom: 0.5rem;
}

.searchFilter-v2 .material-input .mat-form-field-underline {
  display: none;
}

.searchFilter-v2 .material-input .mat-form-field-infix {
  border: 0;
  padding: 0.4rem 0 0rem;
  font-size: 13px;
}

.searchFilter-v2 .mat-form-field-appearance-fill .mat-select-arrow-wrapper {
  top: 3px;
  position: relative;
}

.searchFilter-v2 .mat-form-field-appearance-fill .mat-select-arrow-wrapper .mat-select-arrow {
  margin: 0;
}


.searchFilter-v2 .material-input {
  flex-grow: 1;
  border-right: 1px solid #e6e6e6;
  padding: 0.2rem 0;
  font-size: 14px;
  min-width: 100px;
}

.searchFilter-v2 .field-date .mat-form-field-wrapper .mat-form-field-flex {
  border: 0px;
  border-right: 1px solid #e6e6e6;
}

.searchFilter-v2 .button {
  display: flex;
}

.border-rd0 {
  border-radius: 0px !important;
}

.searchFilter-v2 .material-input .mat-form-field-flex .mat-form-field-label {
  padding-top: 6px;
}

.searchFilter-v2 .field-date .mat-form-field-wrapper .mat-form-field-flex {
  font-size: 13px;
  padding-top: 11px;
  padding-bottom: 32px;
}

.searchFilter-v2 .button .btn {
  font-weight: 400;
  font-size: 15px;
}


.searchFilter-v2 .field-date .mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  position: absolute;
  top: 92%;
}

/* Mat select Control */

.mat-select-control .mat-form-field-wrapper {
  padding: 0.483rem 0.3rem 0.483rem 0.75rem;
  border: 1px solid #ced4da;
  overflow: hidden;
  height: calc(2.25rem + 2px);
}

.mat-select-control .mat-form-field-wrapper .mat-form-field-flex {
  background: transparent;
  padding: 0;
  border-radius: 0px;
  display: flex;
  align-items: center;
}

.mat-select-control .mat-form-field-wrapper .mat-form-field-label-wrapper {
  top: -.55em;
}

.mat-select-control.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  display: none;
}

.mat-select-control .mat-form-field-wrapper .mat-form-field-infix {
  border: 0px;
  padding: 0;
  margin: 0px;
  vertical-align: middle;
  width: auto;
}

.mat-select-control .mat-form-field-wrapper .mat-select-trigger {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

.mat-select-control .mat-form-field-wrapper .mat-select-trigger .mat-select-value {
  max-width: 100%;
  font-size: 13px;
  margin-right: 5px;
}

.mat-select-control .mat-form-field-wrapper .mat-select-trigger .mat-select-arrow-wrapper {
  margin-left: auto;
  transform: none;
}

.mat-select-control .mat-form-field-wrapper .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  width: 9px;
  height: 9px;
  border: 1px solid;
  border-bottom: 0;
  border-left: 0;
  border-radius: 1px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  border-color: #000;
  margin-top: -4px;
}

.mat-select-control .mat-form-field-underline,
.mat-select-control .mat-form-field-subscript-wrapper {
  display: none;
}

.mat-select-control.mat-select-line .mat-form-field-wrapper {
  border: 0px;
  padding-left: 0px;
}

.mat-select-control.mat-select-lg .mat-form-field-wrapper {
  padding: 1.1rem 0.3rem 1.1rem 0.75rem;
}

/****** Tag input ******/
.searchField tag-input.form-control,
.searchFilter-v2 tag-input.field-tag {
  padding: 0px;
  min-height: 52px;
  height: auto;
  border-right: 1px solid #e6e6e6;
}

tag-input.field-tag .ng2-tag-input {
  margin: 0px;
  padding: 6px 0;
  border: 0px !important;
}

tag-input.field-tag .ng2-tag-input__text-input {
  width: 256px;
  font-size: 12px;
  height: 100%;
}

tag-input.field-tag .ng2-tag-input__text-input[aria-label="+ Tag"] {
  width: 72px;
}

tag-input.field-tag delete-icon {
  width: 12px !important;
}

tag-input.field-tag delete-icon svg {
  height: 12px !important;
  vertical-align: -3px !important;
  -webkit-transform: none !important;
  transform: none !important;
}

tag-input delete-icon:hover {
  -webkit-transform: none !important;
  transform: none !important;
}

tag-input.field-tag .tag-wrapper {
  line-height: 1rem;
  align-items: center;
  font-size: 9px;
}

tag-input tag:hover,
tag-input tag:active,
tag-input tag:focus {
  background: #e2e2e2 !important;
  color: initial;
  box-shadow: none !important;
}

tag-input.field-tag .ng2-tags-container {
  max-width: 426px;
  position: relative;
  flex-wrap: nowrap !Important;
  align-items: center;
  padding-left: 8px;
  padding-top: 9px;
  ;
}

tag-input.field-tag .ng2-tags-container tag {
  height: auto;
}

.tag-border {
  border-bottom: 1px solid #ced4da;
}

.form-tag-report tag-input.field-tag .ng2-tag-input__text-input[aria-label="+ Tag"] {
  width: 100%;
}

.form-tag-report tag-input.field-tag .ng2-tags-container tag {
  width: auto;
  max-width: none;
  overflow: visible;
}

.form-tag-report tag-input.field-tag .ng2-tag-input {
  padding: 0px;
}

.form-tag-report tag-input.field-tag .ng2-tags-container {
  max-width: 100%;
  padding: 0px;
  flex-wrap: wrap !important;
}

.form-tag-report tag-input-form {
  width: auto;
  min-height: 32px;
  display: flex;
  align-items: center;
}

table td tag-input.field-tag .ng2-tags-container {
  flex-wrap: wrap !important;
  min-width: 232px;
  padding: 0px !important;
}

table td tag-input.field-tag .ng2-tag-input__text-input {
  padding-left: 0px;
  width: 100%;
}

/********** FOrm Tag Css ***********/

.form-tag {
  max-width: 200px;
  border-right: 1px solid #e6e6e6;
  padding-right: 10px;
  min-width: 200px;
}

.form-tag input {
  font-size: 13px !important;
  width: 100%;
  padding: 0;
}

.form-tag tag-input-form {
  width: 100%;
}

.advancesearch .form-tag tag-input-form {
  max-width: 100%;
}

.advancesearch .form-tag .ng2-tags-container tag+tag-input-form {
  max-width: 100px;
}

.form-tag .ng2-tag-input__text-input {
  width: 100%;
  padding: 0;
}

.form-tag .ng2-tags-container {
  flex-wrap: nowrap !important;
  align-items: center;
  height: 100%;
}

.form-tag tag {
  font-size: 10px !important;
  letter-spacing: 0 !important;
  margin: 0.1rem 0.1rem 0.1rem 0 !important;
  padding: 0.08rem 0.25rem !important;
  min-width: 38px;
  height: 22px !important;
  line-height: 22px !important;
}

.ng2-tag-input {
  border: 0px !important;
}

.form-tag delete-icon {
  width: 13px !important;
  height: 16px !important;
}

.form-tag delete-icon svg {
  vertical-align: -2px !important;
  height: 12px !important;
}

.searchFilter-v2 .form-tag .ng2-tag-input.ng2-tag-input--focused {
  border-bottom: 0px;
}

.searchFilter-v2 .ng2-tag-input {
  height: 45px;
}

.form-inputtag tag-input.field-tag .ng2-tags-container {
  padding: 0;
  flex-wrap: wrap !important;
  max-width: 100%;
}

.form-inputtag tag-input.field-tag .ng2-tag-input__text-input {
  padding-left: 0px;
}

.form-line-tag.form-tag-report {
  border: 0;
  border-bottom: 1px solid #e6e6e6;
  padding-top: 0;
  padding-left: 0;
}

.form-line-tag tag-input.field-tag form {
  width: 100%;
  display: inline-block;
}

.form-line-tag tag-input.field-tag .ng2-tag-input__text-input {
  padding-left: 0;
  width: 100% !important;
}

.form-line-tag tag-input.field-tag .tag-wrapper {
  letter-spacing: 0;
  font-size: 12px;
  color: #1a1a1a;
  font-weight: 300;
  font-family: 'Poppins';
}

.form-tag-report tag-input.field-tag .ng2-tags-container tag {
  padding: 5px 7px !important;
  height: 26px !important;
  line-height: 26px !important;
  font-weight: 300;
}

.nav-tabs-bg {
  background: #f5f7f8;
}

.nav-tabs-bg .nav-item .nav-link {
  padding: 0.9rem 1.5rem
}

.nav-tabs-bg .nav-item .nav-link.active {
  border: 0px !important;
  background: #0d4e94 !important;
  color: #fff !important;
  border-radius: 0px;
}

.videorecorded-menu {
  max-width: 207px;
  min-width: 207px !Important;
  height: auto;
  background: transparent !important;
  box-shadow: none !important;
  z-index: 1050;
  overflow: visible !important;

  .recording-tooltip {
    background: #fff;
    border-radius: 4px;
    border: 0px solid #ebebeb;
    box-shadow: 0px 0px 8px rgb(0 0 0 / 13%);

    /* &:before {
      content: "";
      position: absolute;
    width: 20px;
    height: 20px;
    background: #fff;
    transform: rotate(48deg);
    right: 6px;
    box-shadow: 0 0 5px rgb(0 0 0 / 13%);
    z-index: 1;
    top: 5px;

    }*/
    &:after {
      content: "";
      background: transparent;
      position: absolute;
      top: -29px;
      right: -5px;
      width: 31px;
      height: 32px;
      cursor: pointer;
    }

    ul {
      background: #fff;
      position: relative;
      z-index: 1;
      padding-top: 5px;

      li {
        display: flex;
        text-align: left;
        padding: 6px 10px;
        font-size: 13px;
        font-weight: 300;
        line-height: 18px;

        .time {
          margin-left: auto;
          color: #3861fb;
          font-weight: 300;
          font-size: 11px;
          text-align: right;
        }

        .date {
          opacity: 0.6;
          font-size: 11px;
        }

        .size {
          color: #000000;
          opacity: 0.5;
          font-size: 10px;
          font-style: italic;
          display: block;
        }

        h4 {
          font-weight: 400;
          margin-bottom: 2px;
        }
      }
    }
  }
}

.jobdetailtooltip {
  max-width: 300px !important;
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

/*** New Card Design **/
.card-list {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  margin-left: -7.5px;
  margin-right: -7.5px;
  position: relative;
  overflow: hidden;

  .card-items {
    position: relative;
    overflow: hidden;
    flex-basis: 25%;
    min-width: 25%;
    padding: 7.5px;

    @media (max-width: 990px) {
      flex-basis: 33.33%;
      min-width: 33.33%;
    }

    @media (max-width: 767px) {
      flex-basis: 50%;
      min-width: 50%;
    }

    @media (max-width: 576px) {
      flex-basis: 100%;
      min-width: 100%;
    }

    .card-box {
      position: relative;
      overflow: hidden;
      background: #FFFFFF;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
      border-radius: 5px;
      padding: 5px 10px;
      height: 100%;
      display: flex;
      flex-direction: column;

      ul.action-icons {
        display: flex;
        display: -webkit-flex;
        justify-content: flex-end;

        li {
          margin-left: 3px;
          cursor: pointer;

          i,
          .mat-icon {
            font-size: 14px;
            color: #697fcd;
            width: auto;
            height: auto;

            &:hover {
              fill: #0d4e94;
            }
          }

          svg {
            fill: #697fcd;

            &:hover {
              fill: #0d4e94;
            }
          }

          mat-icon {
            font-size: 15px;
          }

          .checkicon {
            margin-right: 0;

            .mat-checkbox-layout {
              background: transparent;
              border: 1px solid #CFD8F9;
              color: #CFD8F9;
              width: 12px;
              height: 12px;

              .mat-checkbox-inner-container {
                width: 100%;
                height: 100%;
                vertical-align: top;

                .mat-checkbox-ripple {
                  display: none;
                }

                .mat-checkbox-checkmark-path {
                  stroke: #CFD8F9 !important;
                }

                svg.mat-checkbox-checkmark {
                  width: 10px;
                  margin: 0;
                }

              }

            }

            .mat-checkbox-checked {
              label.mat-checkbox-layout {
                background: #0d4e94;
                border-color: #0d4e94;
              }
            }
          }
        }
      }

      .accessid {
        span {
          background-color: #f0f6ff;
          color: #3861fb;
          font-size: 10px;
          padding: 3px 5px;
          display: inline-block;
          border-radius: 3px;
        }
      }

      .user-detail {
        display: flex;
        display: -webkit-flex;
        margin-bottom: 7px;

        .image {
          text-align: center;
          margin-top: -13px;

          .img {
            width: 55px;
            height: 55px;
            border-radius: 100%;
            overflow: hidden;
          }

        }

        .detail {
          flex-basis: 100%;
          padding-left: 8px;
          padding-top: 5px;

          .name {
            text-align: left;
            font-size: 15px;
            letter-spacing: 0px;
            color: #0B1C2E;
            text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.09);
            margin-bottom: 1px;
            display: inline-block;
          }

          .email {
            color: #0B1C2E;
            font-size: 12px;
            font-weight: 300;
            font-style: italic;
            margin: 5px 0;
          }

          .rating-star {
            margin-top: 5px;

            .rating {
              color: #FF9900;

              i {
                color: #FF9900;
                font-size: 15px;
                margin-right: 2px;
              }
            }
          }

        }
      }

      .experience {
        background: #3861FB;
        border-radius: 3px;
        color: #fff;
        display: inline-block;
        font-size: 10px;
        font-weight: 500;
        padding: 3px;
      }

      .skills {
        font-size: 11px;
        font-weight: 300;
        margin: 5px 0;
        line-height: 1.4;

        label {
          letter-spacing: 0.51px;
          padding: 0px;
          color: #0052cc;
          font-size: 11px;
          font-weight: 500;
          margin-right: 2px;

          &.bg-lightgreen {
            background-color: #eafff1;
            color: #00a031 !important;
            border-radius: 3px;
            padding: 3px 4px;
            font-size: 9px;
            font-weight: 400;
            display: inline-block;
            margin-right: 4px;
          }
        }

        &.secondary {
          label {
            color: #000;
          }
        }

        span {
          text-transform: uppercase;
          word-break: break-all;
        }

        &.job-detail {
          span {
            text-transform: inherit;
          }
        }
      }

      .scheduled-date {
        display: flex;
        display: -webkit-flex;
        margin: auto 0 2px;
        padding-top: 4px;

        .date-box {
          font-size: 12px;
          font-weight: 300;
          color: #0C4D92;
          padding: 3px 3px;
          line-height: 1.3;
          display: flex;
          align-items: center;
          margin: 0;

          .mat-icon {
            width: auto;
            height: auto;
            font-size: 18px !important;
            margin-right: 5px;
            color: rgb(29, 201, 183);
          }

          &:last-child {
            margin-left: auto;
          }

          &:first-child {
            margin-left: 0px;
          }

          /*&.tech-date { background: #F4F4F4; color: #58657E}*/
        }
      }

      .date-text-grey {
        font-size: 10px;
        color: #a2a1a1;
        padding-top: 6px;
      }

      .text-grey {
        color: #707070 !important;
      }

      .gray-box {
        background-color: #eff2f5;
        color: #000;
        border-radius: 5px;
        padding: 5px 8px;
        font-size: 11px;
        font-weight: 600;
        display: flex;
        align-items: center;

        &:empty {
          display: none !important;
        }
      }

      .dateBox {
        background: #EFF6FE;
        border-radius: 3px;
        font-size: 8px;
        font-weight: 500;
        color: #0C4D92;
        padding: 3px 4px;
        line-height: 1;
        display: flex;
        text-transform: uppercase;
        align-items: center;
        margin: 0;
      }

      .assesment-date {
        .date-box {
          font-size: 10px;
          font-weight: 500;
        }
      }

      .user-icons {
        display: flex;
        display: -webkit-flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 5px 0;

        a {
          color: #dbdbdb;
          font-size: 16px;
        }

        .mat-icon {
          width: auto;
          height: auto;
          font-size: 16px !important;
        }
      }



    }
  }
}

.mat-custom-checkbox span.mat-radio-outer-circle {
  border-radius: 0px;
}

.mat-custom-checkbox.mat-radio-checked .mat-radio-outer-circle {
  background-color: #0d4e94 !important;
}

.mat-custom-checkbox.mat-radio-checked .mat-radio-inner-circle {
  transform: rotate(45deg) !important;
  height: 12px;
  width: 6px;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  background-color: transparent;
  left: 5px;
  border-radius: 0px;
}

.mat-dialog-container {

  border-radius: 10px !important;

}

.acknowlegment-modal .check-box>label .mat-checkbox {
  margin-top: 5px;
}

.acknowlegment-modal .check-box>label .mat-checkbox .mat-checkbox-inner-container {
  width: 20px;
  height: 20px;
  border-radius: 4px;
  border-color: #0d4e94;
}

.acknowlegment-modal .check-box>label .mat-checkbox .mat-checkbox-frame {
  border-color: #0D4E94;
}

.mat-button[disabled] {
  background: #DEDFE0;
  border-color: #DEDFE0;
  color: #867B7B;
}

.btn-primary[disabled="true"] {
  background: #DEDFE0 !important;
  border-color: #DEDFE0 !important;
  color: #867B7B !important;
}

.dateTable input {
  background: #fff;
  width: 125px;
  padding-right: 20px;
}

.dateTable {
  position: relative;
  display: block;
}

.dateTable .mat-icon {
  right: 0px;
  top: 11px;
  z-index: 1;
  position: absolute;
  font-size: 17px;
  cursor: pointer;
}

.dateTable input {
  background: #fff !important;
  width: 100px
}

.tableInput {
  width: 110px !important;
  padding: 0px 5px;
}

.print-pdf {
  display: none !important
}

@media print {
  .print-pdf {
    display: block !important
  }

  .print-hideelement {
    display: none !important
  }
}

.modal-editinterviewer {
  max-width: 1300px !important;
  width: 98% !important;
  height: auto !important;
  min-height: 325px;
}

.badgedefault {
  border: 0px;
  padding: 5px;
  border-radius: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 83px;
  overflow: hidden;
  display: inline-block;
  font-size: 10px;
  font-weight: 500;
  margin: 2px;
  cursor: pointer;
  line-height: 1;
  text-align: center;
}

.badgedefault:empty {
  display: none;
}

.badge-defaultshade {
  background: #f0f6ff;
  color: #3861fb;
}

.badge-shadegreen {
  color: #0c5460;
  background-color: #d1ecf1;
}

.badge-shadered {
  color: #a50e0e;
  background: #fce8e6;
}

.badge-shadeyellow {
  color: #856404;
  background-color: #fff3cd;
}


.verifytab ul.verify-list {
  margin-top: 0.8rem;
}

.verifytab ul.verify-list li {
  padding: 8px 0 0;
  color: #979797;
  font-size: 12px;
  text-transform: capitalize;
}

.verifytab ul.verify-list li span {
  margin-left: auto;
  font-style: italic;
  color: #343434;
}

.nonverify {
  background: rgb(235, 82, 97);
}



.mat-form-field.mat-selectfield,
.mat-form-field.mat-inputfield {
  width: 100%;
}

.mat-form-field.mat-selectfield .mat-form-field-infix,
.mat-form-field.mat-inputfield .mat-form-field-infix {
  border-top: 0px;
  font-size: 13.5px;
  font-weight: 300;
  font-family: 'Poppins';
  width: 100%;
}

.mat-form-field.mat-selectfield span.mat-select-value-text,
.mat-form-field.mat-inputfield .mat-input-element {
  font-size: 13.5px;
  font-weight: 300;
  color: #1a1a1a;
  line-height: 1.2;
}

.mat-form-field.mat-selectfield .mat-select {
  font-family: "Poppins", sans-serif !important;
}

.mat-dialog-container {
  max-height: 99vh !important;
}

.modal-scroll {
  max-height: calc(100vh - 28px - 96px);
  overflow: auto;
  margin: 0 -1rem;
  padding: 0 1rem;
}

.mat-form-field.mat-inputfield textarea.mat-input-element {
  min-height: 21px;
}

.mat-form-field.mat-inputfield textarea.mat-input-element.textareadaytohire {
  max-height: 200px;
}

.mat-form-field.mat-inputfield .info-icon {
  pointer-events: auto;
  margin-left: 2px;
}


.mat-form-field.mat-selectfield .mat-form-field-underline,
.mat-form-field.mat-inputfield .mat-form-field-underline {
  background: #d8d7d7;
  transform: none;
  bottom: 0px;
}

.mat-form-field.mat-selectfield .mat-form-field-wrapper,
.mat-form-field.mat-inputfield .mat-form-field-wrapper {
  padding-bottom: 0;
}

.mat-form-field.mat-selectfield .mat-form-field-underline .mat-form-field-ripple,
.mat-form-field.mat-inputfield .mat-form-field-underline .mat-form-field-ripple {
  display: none;
}


.mat-form-field.mat-selectfield .mat-form-field-label,
.mat-form-field.mat-inputfield .mat-form-field-label {
  color: #1a1a1a;
  font-size: 13.5px;
  font-weight: 300;
  line-height: 1.2;
}

.mat-form-field-appearance-standard.mat-form-field-disabled .mat-form-field-underline {
  background: #d8d7d7 !important;
}

.mat-form-field.mat-selectfield .mat-form-field-label .mat-placeholder-required,
.mat-form-field.mat-inputfield .mat-form-field-label .mat-placeholder-required {
  color: red;
}

.mat-selectfield.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-inputfield.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  color: #0d4e94;
  transform: translateY(-1.34375em) scale(1);
  font-size: 11px;
}

.mat-form-field.mat-selectfield .mat-select-arrow {
  display: block;
  width: 7px;
  height: 7px;
  border: 0px;
  border-top: 1px solid #BEBEBE;
  border-left: 1px solid #BEBEBE;
  transform: rotate(225deg);
}

.mat-form-field.mat-selectfield.mat-focused .mat-select-arrow {
  transform: rotate(225deg) !important;
}

.mat-form-field.mat-selectfield .mat-select:not(.mat-select-empty) .mat-select-arrow-wrapper {
  transform: none !important;
}

.dropdown-tooltip .dropdown-toggle {
  -webkit-appearance: none;
  appearance: none;
  vertical-align: middle;
}

.dropdown-tooltip .dropdown-toggle::after {
  display: none;
}

.dropdown-tooltip .dropdown-toggle span.material-icons {
  font-size: 14px;
  color: #697fcd;
  vertical-align: bottom;
}

.dropdown-tooltip .dropdown-menu {
  border: 0px solid #e6e6e6;
  box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
  padding: 8px;
  min-width: 230px;
}

.dropdown-tooltip .dropdown-menu ul li {
  margin-bottom: 8px;
  margin-left: 0px !important;
  font-weight: 400;
  color: #373737;
  font-size: 11px;
  font-weight: 300;
  line-height: 1.1;
}

.dropdown-tooltip .dropdown-menu ul li:last-child {
  margin-bottom: 0px;
}

.dropdown-tooltip .dropdown-menu ul li label {
  font-size: 11px;
  font-weight: 400;
  margin-right: 10px;
  min-width: 90px;
  text-align: right;
}

.dropleft .dropdown-toggle::before {
  display: none;
}

.dropleft .dropdown-menu {
  margin-top: 25px;
  margin-right: -10px;
}

/*.dropdown-tooltip .dropdown-menu.dropdown-menu-left.show {
  left: auto!important;
  right: 0;
}*/
/* Modal Edit iterviewer */
.modal-editinterviewer .mat-dialog-container .close {
  position: absolute;
  right: 15px;
  z-index: 2;
}

.modal-editinterviewer .mat-form-field.mat-inputfield textarea.mat-input-element {
  min-height: 21px;
  max-height: 222px;
}

body.modal-open .modal-editinterviewer .mat-dialog-container .close {
  z-index: 0;
}

.modal-editinterviewer .mat-dialog-container {
  position: relative;
}

.modal-editinterviewer .mat-dialog-container .mat-tab-body-content {
  padding: 30px 15px 0px;
}

.modal-editinterviewer #interviewerListModal {
  background: rgb(0 0 0 / 23%);
}

.modal-editinterviewer #interviewerListModal .modal-body {
  padding-bottom: 15px !important;
}



.modal-editinterviewer #interviewerListModal .modal-body .table-responsive.table-sticky {
  margin-bottom: 9px;
  max-height: calc(100vh - 259px);
  padding: 0px 0px 6px;
}

.modal-editinterviewer .field-addinterview {
  border-bottom: 0px !important;
}

.modal-editinterviewer .modal-footer {
  padding-bottom: 0px !important;
}

.modal-editinterviewer .modal-dialog.modal-interviewlist {
  min-height: calc(100% - 3.5rem);
  display: flex;
  align-items: center;
}

.modal-editinterviewer .mat-tab-label-container .mat-tab-labels .mat-tab-label {
  padding: 0;
  min-width: auto;
  margin-right: 30px;
  padding: 0.9rem 0rem;
  font-size: 15px;
  font-weight: 400;
  background: transparent !important;
}

.modal-editinterviewer .mat-tab-header {
  margin: -15px;
  padding: 0 15px;
}

/** FullFilment Page css */
.fullfilment-page {
  padding: 4px 15px 15px;
  position: relative;
  overflow: hidden;
}

.fullfilment-page .mat-tab-label {
  padding: 0 10px;
  min-width: auto;
  margin-right: 1rem;
  font-size: 15px;
}

.fullfilment-page .box-action {
  position: absolute;
  right: 0;
  top: 0px;
  display: flex;
  align-items: center;
}

.fullfilment-page mat-tab-header.mat-tab-header {
  margin-bottom: 10px;
}

.fullfilment-page .mat-tab-body-wrapper,
.fullfilment-page .mat-tab-body {
  margin-top: -59px;
  padding-top: 56px;
}

.fullfilment-page .box-action .select-assign {
  display: flex;
  align-items: center;
  height: 34px;
  border-radius: 3px;
  overflow: hidden;
  border: 1px solid #f1f1f1;
  margin-left: 10px;
}

.fullfilment-page .box-action {
  position: absolute;
  right: 0;
  top: 0px;
  display: flex;
  align-items: center;
  height: 48px;
}

.fullfilment-page .box-action .select-assign .mat-input-element {
  border: 0px;
  height: 31px;
  padding-left: 0.375rem;
  width: 430px !important;
  padding-right: 26px;
  box-sizing: border-box;
  font-size: 14px;
}


.fullfilment-page .box-action .mat-groupfield .mat-form-field-wrapper {
  margin: 0px;
  padding: 0;
}

.fullfilment-page .box-action .mat-groupfield .mat-form-field-wrapper .mat-form-field-outline {
  display: none;
}

.fullfilment-page .mat-form-field-appearance-outline.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  display: none;
}

.fullfilment-page .box-action .mat-groupfield.mat-form-field-appearance-outline .mat-form-field-infix {
  padding: 0;
  margin: 0;
  border: 0px;
  font-size: 14px;
  width: auto;
  min-width: 122px;
}

.fullfilment-page .box-action .mat-groupfield.mat-form-field-appearance-outline .mat-form-field-infix .mat-placeholder-required {
  display: none;
}

.fullfilment-page .box-action .mat-groupfield.mat-form-field-appearance-outline .mat-select-arrow-wrapper {
  padding-top: 7px;
}

.fullfilment-page .box-action .mat-groupfield .mat-select-value {
  font-size: 14px;
  line-height: 1.5;
}

.fullfilment-page .box-action .mat-form-field-appearance-outline .mat-form-field-label {
  top: 19px;
  font-size: 14px;
}

.mat-checkbox .mat-checkbox-ripple {
  display: none !important;
}

.fullfilment-page .mat-tab-label-container {
  background: #fff;
  z-index: 2;
  flex-grow: initial;
}

.fullfilment-page .box-action .btn-gray {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
}

.btn-gray {
  background: #e9e9e9;
}

.btn-gray:disabled,
.btn-gray.disabled {
  background: #f3f3f3;
  opacity: 0.65;
  pointer-events: none;
}

.mat-input-element:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.fullfilment-page .box-action .btn .mat-icon {
  font-size: 17px;
  line-height: 23px;
}

.fullfilment-page .table-responsive {
  max-height: calc(100vh - 229px);
}

.fullfilment-page .table-responsive.table-group {
  max-height: calc(100vh - 185px);
}

.fullfilment-page .mat-tab-body-content {
  overflow: hidden;
}

.fullfilment-page .table-footer {
  text-align: right;
  font-size: 14px;
}

.fullfilment-page .table-footer .total-number {
  color: #6c6c6c;
  font-size: 13px;
  margin-left: 1.5rem;
}

.mat-checkbox-sm .mat-checkbox-inner-container {
  width: 12px;
  height: 12px;
}

.mat-checkbox-sm .mat-checkbox-frame {
  border-width: 1px;
}

.mat-checkbox-md .mat-checkbox-inner-container {
  width: 16px;
  height: 16px;
}

.mat-checkbox-md {
  font-family: "Poppins", sans-serif;
  font-size: 13.5px;
  font-weight: 300;
  color: #565656;
  line-height: 1.2;
}

.mat-checkbox-md .mat-checkbox-layout .mat-checkbox-label {
  line-height: 16px;
}

.mat-checkbox-md label.mat-checkbox-layout {
  align-items: center;
}

.form-control-sm {
  font-size: 10px;
  height: calc(2.25rem - 7px);
}


input[type="search"]::-webkit-search-cancel-button {
  display: none;
}


.matselectBottomopen {
  margin-top: 23px;
}

.matselectBottomopen span.mat-option-text {
  font-size: 14px;
}

.matselectBottomopen .mat-option {
  font-size: 14px !important;
  line-height: 1.8em !important;
  height: auto !important;
  padding: 4px 10px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #000;
}

.table-secondary {
  background: transparent;
  border: 0px;
  border-collapse: separate;
  border-spacing: 0 5px;
  margin-bottom: 0px;
}

.table-secondary thead th,
.table-secondary th {
  background: #f6f6f7;
  border: 0px;
  color: #3b1a1a;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  padding: 0.9rem 6px;
  vertical-align: middle;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.table-secondary thead th:first-child {
  border-radius: 4px 0px 0px 4px;
}

.table-secondary thead th:last-child {
  border-radius: 0px 4px 4px 0px;
}

.table-secondary td {
  border: 0;
  font-weight: 400;
  font-size: 13px;
  color: #020609;
  padding: 8px 6px;
  border-bottom: 1px solid #f6f6f6;
  vertical-align: middle;
}

.table-primary {
  background: transparent;
  border: 0px;
  border-collapse: separate;
  border-spacing: 0 0px;
  margin-bottom: 0px;
}

.table-primary thead th {
  background: #0d4e94;
  border: 0px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  padding: 0.9rem 6px;
  vertical-align: middle;
  position: sticky;
  top: 0px;
  z-index: 1;
}

.table-primary td {
  border: 0;
  font-weight: 400;
  font-size: 13px;
  color: #020609;
  padding: 8px 6px;
  border-bottom: 1px solid #f6f6f6;
  vertical-align: middle;
}

.table-primary tr:hover {
  background: #f7f7f7;
}

.mathire-select.mat-form-field.mat-inputfield .mat-input-element {
  font-size: 14px;
}

.mathire-select.mat-inputfield.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mathire-select.mat-selectfield.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  color: #9b9b9b;
  font-size: 14px;
}

.mathire-select.mat-form-field.mat-selectfield span.mat-select-value-text,
.mathire-select.mat-form-field.mat-inputfield .mat-input-element {
  font-size: 14px;
}

/*
.mathire-select.mat-form-field.mat-selectfield .mat-form-field-label-wrapper {
  top: -6px;
}*/
.textareadaytohire textarea.mat-input-element {
  min-height: 18px !important;
}

.textareadaytohire .mat-form-field-infix {
  padding-bottom: 4px !important;
}

.mathire-select.mat-form-field.mat-selectfield .mat-form-field-label,
.mathire-select.mat-form-field.mat-inputfield .mat-form-field-label {
  font-size: 14px;
}


.matprefixField .prefixicon img {
  width: 14px;
  height: 14px;
  opacity: 0.6;
}

.matprefixField .mat-form-field-infix {
  padding-left: 9px;
}

.matprefixField .mat-form-field-label-wrapper {
  left: 10px;
}

.matprefixField.mat-inputfield.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label-wrapper {
  left: -13px;
}

.green-color {
  color: green;
}

.enrollment-tooltip {
  max-width: 255px !important;
}

.material-selectdefault {
  line-height: 1;
  height: 37px;
  width: 112px;
  margin-right: 15px;
}

.material-selectdefault .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.material-selectdefault .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}

.material-selectdefault .mat-form-field-subscript-wrapper {
  display: none;
}

.material-selectdefault .mat-form-field-wrapper .mat-form-field-infix {
  padding: 0px;
  margin: 0px;
  border: 0px;
}

.material-selectdefault .mat-select {
  font-size: 13px;
  color: #747373;
}


.material-selectdefault .mat-form-field-flex {
  background: #e6e6e6;
  padding: 10px 10px !important;
  border-radius: 3px !important;
  height: 37px !important;
}

.mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: #111 !important;
}

.material-selectdefault .mat-select-value {
  color: #747373;
  font-size: 15px;
  font-weight: 400;
  width: calc(100% - 18px);
  min-width: calc(100% - 18px);
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: inherit !important;
}

.material-selectdefault .mat-select-trigger {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 100%;
}

.material-selectdefault .mat-select-arrow-wrapper {
  margin-top: 5px;
}

.material-selectdefaultvideo {
  margin-right: 10px;
  width: 90px;
}

.material-selectprofile {
  margin-top: 26px;
}

.material-uploadphoto.material-selectdefault .mat-form-field-flex {
  background: rgba(0, 0, 0, 0.02);
}


/** Datepicker Calendar **/

ngx-daterangepicker-material .md-drppicker {
  display: flex;
  flex-wrap: wrap;
  min-width: 620px;
}

ngx-daterangepicker-material .md-drppicker .buttons {
  flex-basis: 100%;
}

ngx-daterangepicker-material .md-drppicker .ranges {
  flex-basis: 120px;
  min-width: 120px;
}

ngx-daterangepicker-material .md-drppicker th.month {
  vertical-align: middle;
}

ngx-daterangepicker-material .md-drppicker tr.week-days th {
  padding-top: 5px;
}

ngx-daterangepicker-material .md-drppicker tbody.drp-animate {
  margin-top: 10px;
}

ngx-daterangepicker-material .md-drppicker td.available {
  vertical-align: middle;
}

ngx-daterangepicker-material .md-drppicker .buttons .buttons_input {
  display: flex;
  grid-gap: 10px;
  justify-content: flex-end;
}

ngx-daterangepicker-material .md-drppicker .buttons .buttons_input .btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  border-radius: 3px;
  box-shadow: none;
  background: #0d4e94;
}

ngx-daterangepicker-material .md-drppicker .buttons .buttons_input .btn.btn-default {
  background: #f3f3f3;
}

ngx-daterangepicker-material .md-drppicker .buttons .buttons_input .btn.btn-default.clear {
  display: none;
}


ngx-daterangepicker-material .md-drppicker .buttons .buttons_input .btn.clear svg {
  width: 22px;
  height: 22px;
  margin-top: -11px;
  margin-left: 4px;
  display: none;
}

/** Table Secondary Css */
.table-secondary {
  border-collapse: collapse;
}

.table-secondary thead th,
.table-secondary td,
.table-secondary th {
  border: 1px solid #f6f6f6;
  font-size: 14px;
}

.table-secondary td {
  background: #fff;
}

.table-secondary thead th,
.table-secondary th {
  background: #f3f4f8;
  border-color: #e9ecef;
  font-weight: 500;
}

//.table-secondary tr.table-tools th {}

.table-secondary thead .table-tools th {
  background: #fff;
}

.column-menu {
  max-width: 350px;
  min-width: 250px !important;
  padding-bottom: 5px;
}

.column-menu h4 {
  padding: 10px;
  border-bottom: 1px solid #e5e5e5;
  font-size: 15px;
  font-weight: 500;
}

.column-menu .mat-checkbox {
  width: 100%;
  display: block;
  padding: 6px 10px;
  font-size: 17px;
}


.card-form-box .multiselect-dropdown .dropdown-btn {
  height: 40px;
  border: 1px solid #f1f1f1 !important;
  border-radius: 0px !Important;
  font-size: 14px;
  display: flex !important;
  align-items: center;
  padding-right: 24px !important;
}

.card-form-box .multiselect-dropdown .dropdown-btn>span:last-child {
  font-size: 13px;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  white-space: nowrap;
  font-size: 10px;
  display: flex;
  align-items: center;
  padding: 0.08rem 0.25rem !important;
  background: #efefef !important;
  color: #444 !important;
  border: 0px !important;
  border-radius: 16px !important;
  margin-right: 2px !important;
  box-shadow: none !important;
}

.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item a {
  font-size: 11px;
  color: #072035 !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.multiselect-dropdown .dropdown-btn .dropdown-multiselect__caret:before {
  display: block;
  width: 7px;
  height: 7px;
  border: 0px !important;
  border-top: 1px solid #000 !important;
  border-left: 1px solid #000 !important;
  transform: rotate(225deg);
  margin-top: 0px !important;
  top: -3px !important;
}

.multiselect-dropdown .dropdown-list {
  padding-top: 0px !important;
}

.multiselect-dropdown .dropdown-list .filter-textbox {
  padding: 0px !important;
}

.multiselect-dropdown .dropdown-list ul {
  padding: 2px 0 !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:after {
  border-width: 0 0 2px 2px !important;
}

.multiselect-dropdown .dropdown-list .filter-textbox input {
  font-size: 13px;
  padding: 6px 10px !important;
}

.multiselect-dropdown ul.item2 li.multiselect-item-checkbox {
  font-size: 13px;
  padding: 2px 5px !important;
  line-height: 1.5;
}

.multiselect-item-checkbox input[type=checkbox]+div:before {
  border: 1px solid #c0c0c0 !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked+div:before {
  border-color: #337ab7 !important;
}

.multiselect-dropdown ul.item2 li.multiselect-item-checkbox:hover {
  background: #f6f6f6 !important;
}

.multiselect-item-checkbox input[type=checkbox]+div:after {
  width: 6px !important;
  height: 3px !important;
}

.modal-matdelete .modal-header .close span {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 31px;
  display: inline-block;
  /* line-height: 1; */
  /* text-transform: none; */
  /* letter-spacing: normal; */
  /* word-wrap: normal; */
  /* white-space: nowrap; */
  /* direction: ltr; */
  /* -webkit-font-smoothing: antialiased; */
  /* text-rendering: optimizeLegibility; */
  -moz-osx-font-smoothing: grayscale;
  /* font-feature-settings: "liga" */
  /* position: relative; */
  /* top: -13px; */
}

.modal-matdelete .modal-header {
  padding: 0;
  border: 0px;
}


.modal-matdelete .modal-body {
  padding: 0px;
  margin-top: 24px;
  font-size: 15px;
  line-height: 1.7;
  text-align: center;
}

.modal-matdelete .modal-footer {
  border: 0px;
  padding: 0px;
  text-align: center;
  justify-content: center;
  margin-top: 24px;
}

.modal-matdelete .modal-footer .btn {
  font-size: 14px;
  padding: 0.43em 0.8rem;
  min-width: 90px;
}

.modal-matdelete .modal-footer .btn-danger {
  background: #e9e9e9;
  color: #212529;
  border-color: #e9e9e9;
}

.modal-matdelete .modal-header .close {
  display: flex;
  align-items: center;
  line-height: 1;
  height: 34px;
  padding-bottom: 0;
}

a,
.btn,
button {
  cursor: pointer;
}


.select-dropdown .multiselect-dropdown .dropdown-btn {
  border: 1px solid #f1f1f1 !important;
  padding: 0.375rem !important;
  font-size: 14px;
  border-radius: 3px !important;
  font-weight: 400 !important;
  padding-right: 28px !important;
  text-transform: capitalize;
}

.select-dropdown .multiselect-dropdown .dropdown-btn span {
  font-family: 'Poppins';
  color: #111;
}

a,
.btn,
button {
  cursor: pointer;
}

.dashobarddetail-page .mat-tab-body.mat-tab-body-active {
  overflow: hidden;
}


.dashboard-filterdropdown .multiselect-dropdown {
  min-width: 192px;
}


.form-tag-report .ng2-tags-container {
  flex-wrap: wrap !important;
  min-width: 100%;
}

.form-tag-report tag {
  border-radius: 3px;
}

h3.subTitle {
  border-top: 1px solid #ebe8e8;
  padding-top: 11px;
  margin-bottom: 14px;
  font-size: 14px;
}

.form-value-label {
  font-size: 12px;
  margin-bottom: 15px;
}

.form-value-label>label {
  font-size: 12px;
  font-weight: 500;
}

.form-tag-report {
  max-width: 100%;
  border: 1px solid #e6e6e6;
  padding: 4px;
  min-width: 100%;
  max-height: 200px;
  overflow: auto;
}

.spinner-three-bounce .bounce1,
.spinner-three-bounce .bounce3 {
  display: none !important;
}

@keyframes loaderrotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


@-webkit-keyframes loaderrotate {
  from {
    -webkit-transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
  }
}

.spinner-three-bounce .bounce2 {
  background: transparent !important;
  width: 60px !important;
  height: 60px !important;
  border: solid 4px #14539a;
  border-radius: 50% !important;
  border-right-color: transparent;
  border-bottom-color: transparent;
  -webkit-transition: all 0.5s ease-in !important;
  -webkit-animation-name: loaderrotate !important;
  -webkit-animation-duration: 1.0s !important;
  -webkit-animation-iteration-count: infinite !important;
  -webkit-animation-timing-function: linear !important;
  transition: all 0.5s ease-in !important;
  animation-name: loaderrotate !important;
  animation-duration: 1.0s !important;
  animation-iteration-count: infinite !important;
  animation-timing-function: linear !important;
  animation-delay: 0s !important;
}

.cc-window.cc-banner {
  line-height: 0.5 !important;
}

.fullfilment-page .addjobForm {
  padding: 15px 15px 15px;
}

.addjobForm h3 {
  display: none;
}

.fullfilment-page .addjobForm h3 {
  display: block;
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.edit-job-modal .mat-tab-label {
  padding: 0 10px;
  background: transparent !important;
  min-width: auto;
  margin-right: 1rem;
  font-size: 15px;
}

.mat-modal-tab.edit-job-modal form.addjobForm {
  padding: 15px 8px;
}

.mat-modal-tab.edit-job-modal .mat-tab-header {
  margin: -15px 0 0;
}


.autocomplete-field .mat-form-field-autofill-control {
  border: 1px solid #f1f1f1;
  border-radius: 3px;
  color: #111111;
  box-shadow: none;
  padding: 0.375rem 24px 0.375rem 0.375rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  outline: none;
  height: calc(2.25rem + 4px);
  font-family: Roboto, "Helvetica Neue", sans-serif;
  width: 100%;
  box-sizing: border-box;
}

.autocomplete-field {
  position: relative;
}

.autocomplete-field:after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid;
  margin: 0 4px;
  position: absolute;
  right: 6px;
  top: 20px;
  color: rgba(0, 0, 0, 0.54);
}

.form-check.mat-custom-check {
  position: relative;
}

.mat-custom-check .form-check-input {
  position: absolute;
  opacity: 0;
}

.mat-custom-check .form-check-input+label.form-check-label {
  position: relative;
  cursor: pointer;
  padding: 0;
  font-size: 13px;
  line-height: 1;
}

.mat-custom-check .form-check-input+label.form-check-label:before {
  content: "";
  margin-right: 4px;
  display: inline-block;
  vertical-align: text-top;
  width: 16px;
  height: 16px;
  background: white;
  border-radius: 2px;
  border: 2px solid #0000008a;
  margin-top: 1px;
}

.mat-custom-check .form-check-input:checked+label.form-check-label:before {
  background: #0d4e94;
  border-color: #0d4e94;
}

.mat-custom-check .styled-checkbox:disabled+label.form-check-label {
  color: #b8b8b8;
  cursor: auto;
}

.mat-custom-check .form-check-input:disabled+label.form-check-label:before {
  box-shadow: none;
  background: #ddd;
}

.mat-custom-check .form-check-input:checked+label.form-check-label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid #FFF;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  z-index: 2;
}


.mat-placeholder-required {
  color: red;
}

.select-without-label.mat-form-field-appearance-standard .mat-form-field-flex {
  padding-top: 0px;
}

.select-without-label.mat-form-field-appearance-standard .mat-form-field-infix {
  border-top: 0px;
}

.mat-height-25 {
  height: 25px;
}

.mat-height-25 .mat-form-field-flex {
  padding-top: 0px;
}

.mat-height-25 .mat-form-field-flex .mat-form-field-infix {
  padding-top: 0px;
  border-top: 0px;
}






.mat-select-color {
  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-subscript-wrapper {
    display: none;
  }

  .mat-form-field-flex {
    padding: 0px !important;
    background: transparent;

    span.mat-select-value-text {
      color: #a4abc5;
      font-weight: 500;
      font-size: 11px;

      &:before {
        content: "";
        background: transparent;
        width: 8px;
        height: 8px;
        display: inline-block;
        border-radius: 100%;
        margin-right: 4px;
      }
    }
  }

  .mat-form-field-infix {
    padding: 1px 7px 4px;
  }

  &.available {
    .mat-form-field-flex {
      span.mat-select-value-text {
        &:before {
          background: #3bffb8;
        }
      }
    }
  }

  &.interview {
    .mat-form-field-flex {
      span.mat-select-value-text {
        &:before {
          background: #009ef7;
        }
      }
    }
  }

  &.selected {
    .mat-form-field-flex {
      span.mat-select-value-text {
        &:before {
          background: #50cd89;
        }
      }
    }

  }

  &.offer {
    .mat-form-field-flex {
      span.mat-select-value-text {
        &:before {
          background: #ffc700;
        }
      }
    }
  }

  &.rejected {
    .mat-form-field-flex {
      span.mat-select-value-text {
        &:before {
          background: #f1416c;
        }
      }
    }
  }




  .mat-form-field-label-wrapper {
    display: none;
  }

  .mat-select-arrow {
    overflow: hidden !important;
  }

}

.material-color-select {
  margin-top: 30px !important;
}



.mat-select-color .mat-select-placeholder {
  color: #a4abc5;
  font-weight: 400;
  font-size: 11px;
}

.mat-select-color.mat-form-field.mat-selectfield .mat-select .mat-select-arrow-wrapper {
  transform: none !important;
  position: relative;
  top: -1px;
}

.mat-selectfield.mat-select-color {
  border: 1px solid #e5e5e5;
  border-radius: 50px;
}

.form-mat-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-mat-checkbox label {
  position: relative;
  cursor: pointer;
}

.form-mat-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 2px solid #0000008a;
  padding: 6.4px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 2px;
}

.form-mat-checkbox input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 1px;
  left: 5.5px;
  width: 5.5px;
  height: 11px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.form-mat-checkbox input:checked+label:before {
  background: #0d4e94;
  border-color: #0d4e94;
}

button.mat-menu-item.border-0 {
  border: 0px !important;
}

.mat-menu-panel.menu-subtable {
  min-height: 40px;
}

.videoSection .video-responsive video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  vertical-align: middle;
}
.editjoblist-dialog .heightEditJob{ height: auto!important;}
.scorecard-page .table-responsive {
  max-height: calc(100vh - 239px);
}

.scorecard-page {
  height: calc(100vh - 69px);
}


.scorecard-page .matTabs .mat-tab-header .mat-tab-label {
  margin-left: 0px!important;
}
.loadingText {
  width: 100%;
  color: #fff;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 2000;
  height: 58px;
  padding-top: 122px;
}

/* Material Change css After Update Verion this one is conflict*/
.material-selectv2 .mat-form-field-flex .mat-form-field-infix {padding: 0rem 0 0rem!important;}

.material-selectv2 .mat-form-field-flex {
    padding: .85em 0.5em 0.75em!important;
}

.material-selectv2 .mat-form-field-flex .mat-select-arrow-wrapper {
    transform: translateY(-8%) !important;
}

.material-select.material-select-readonly .mat-form-field-flex {
   background-color: #e9ecef ;
}
.form-radio .mat-ripple.mat-radio-ripple {
  display: none!important;
}
.form-radio  .mdc-radio__background {
  width: 16px;
  height: 16px;
}
.form-radio  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: rgba(0, 0, 0, 0.54)!important;
}

.form-radio  .mdc-radio__inner-circle {
  border-width: 8px;
}

.form-radio  .mat-mdc-radio-button .mdc-radio {
  padding: 0;
  width: 16px;
  height: 16px;
}
.form-radio  .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
  border-color: #0d4e94!important;
}
.form-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {  border-color: #0d4e94!important;}
.host-field .mdc-form-field>label {
  display: none;
}
@media (max-width: 767px){
  .host-field .mdc-form-field>label {
    display: inline-block;
    font-size: 13px;
  }
}
